import React from 'react';
import Popup from '.';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },

  paymentPopupTitle: {
    maxWidth: 600,

    fontSize: "17px",
    fontWeight: "bold",
    color: "black",

    marginTop: 20,

    "&:first-child": {
      marginTop: 0
    },

    "@media(max-width: 1280px)": {
      fontSize: "1.8vw",
    },

    "@media(max-width: 1025px)": {
      fontSize: "2.4vw",
    },

    "@media(max-width: 767px)": {
      fontSize: "3.5vw",
    },

  },

  paymentPopupText: {
    maxWidth: 600,

    fontSize: "15px",
    color: "#111",

    "@media(max-width: 1280px)": {
      fontSize: "1.4vw",
    },

    "@media(max-width: 1025px)": {
      fontSize: "1.8vw",
    },

    "@media(max-width: 767px)": {
      fontSize: "3vw",
    },
  }
}));

const PaymentPopup = ({ isOpen, handleClose }) => {
  const classes = useStyle();

  return (
    <Popup handleClose={handleClose} isOpen={isOpen}>
      <div className={classes.container}>
        <p className={classes.paymentPopupText}>
          На&nbsp;Krypton могут продавать как юридические лица (компании), так и&nbsp;физические лица (обычные люди).
        </p>

        <p className={classes.paymentPopupText}>
          Проведение оплаты товаров от&nbsp;физических лиц отличается от&nbsp;оплаты товаров от&nbsp;юридических. Поэтому провести её&nbsp;одним платежом не&nbsp;получится.
        </p>

        <p className={classes.paymentPopupText}>
          В&nbsp;твоей корзине как раз собрались товары и&nbsp;от&nbsp;юридических, и&nbsp;от&nbsp;физических лиц.
        </p>


        <p className={classes.paymentPopupTitle}>
          Что изменится для меня?
        </p>

        <p className={classes.paymentPopupText}>
          Оплата будет разделена на&nbsp;2&nbsp;части. Для этого откроется 2 вкладки для оплаты. Одна&nbsp;&mdash; за&nbsp;товары от&nbsp;юридических лиц, а&nbsp;вторая&nbsp;&mdash; за&nbsp;товары от&nbsp;физических лиц.
        </p>

        <p className={classes.paymentPopupText}>
          В&nbsp;остальном&nbsp;&mdash; ничего нового.
        </p>
      </div>
    </Popup>
  );
};

export default PaymentPopup;