import { makeStyles } from '@material-ui/core';
import React, { useEffect } from 'react';

import CloseIcon from "../../../static/svg/close.svg";


const useStyle = makeStyles((theme) => ({
  falloff: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,

    backgroundColor: "#00000055",

    display: 'flex',
    alignItems: "center",
    justifyContent: "center",

    zIndex: 1000,
  },

  container: {
    boxSizing: "border-box",
    backgroundColor: "white",

    borderRadius: 20,

    minWidth: 400,
    minHeight: 200,
    maxWidth: 800,

    margin: "0 20px",

    position: "relative",

    "@media(max-width: 767px)": {
      minWidth: "unset",
      minHeight: "unset",
      margin: "10px",
    }
  },

  childrenContainer: {
    boxSizing: "border-box",
    padding: 30,

    "@media(max-width: 767px)": {
      padding: 20,
    },
  },

  topBlock: {
    width: "100%",
    display: "flex",
    justifyContent: 'space-between',

    marginBottom: 10,
    padding: 8,
  },

  closeButton: {
    position: "absolute",
    top: 8,
    right: 8,
    
    padding: 2,
    margin: 0,
    width: 32,
    height: 32,

    borderRadius: 20,

    transition: 'background-color .3s',
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#f4f4f4",

    },
  },
}));


const Popup = ({ isOpen, handleClose, children }) => {
  const classes = useStyle();

  function handleKeyDown(evt) {
    if (evt.code === "Escape") {
      handleClose();
    }
  }

  function handleFallofClick(evt) {
    if (evt.target.classList.contains(classes.falloff)) {
      handleClose();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, []);

  if (!isOpen) {
    return null;
  }

  return (
    <div className={classes.falloff} onClick={handleFallofClick}>
      <div className={classes.container}>
        <div className={classes.closeButton} onClick={handleClose}>
          <CloseIcon width={28} height={28} />
        </div>

        <div className={classes.childrenContainer}>
          {children}
        </div>
      </div>

    </div>
  );
};

export default Popup;